import React, { useEffect } from 'react'

export default function PressRelease() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="291" class="elementor elementor-291">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f967fd3 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="f967fd3" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                style={{ width: "1519px", left: "0px" }}>
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0e88b88"
                        data-id="0e88b88" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-139f61f"
                        data-id="139f61f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-c3ec6a5 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                data-id="c3ec6a5" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-size-default">Optimus and Al Wehda Medical Group Announce Strategic Partnership to Enhance Healthcare Services in Qatar
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-dd15e2b"
                        data-id="dd15e2b" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-4f15cdb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="4f15cdb" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c482a4f"
                        data-id="c482a4f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-57460bb elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="57460bb" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-100 elementor-inner-column elementor-element"
                                        data-id="55fd8e2" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-0ff338c shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="0ff338c" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus is proud to announce a strategic partnership with Al Wehda Medical Group, one of Qatar's most respected healthcare providers. This collaboration marks a significant step forward in our mission to deliver exceptional medical services, streamline patient experiences, and ensure the secure handling of patient data across Al Wehda Medical Group’s extensive network of healthcare centres. </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-c6c55d6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="c6c55d6" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Founded by Dr. Fadi Zuhair Murad over two decades ago, Al Wehda Medical Group has grown from a single clinic in Al-Hilal to seven branches operating under three renowned brands: Al Wehda, Golden, and Atos. This vast network, supported by a team of highly skilled doctors, has consistently provided top-tier healthcare experiences to patients in Qatar. </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-c6c55d6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="c6c55d6" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Through this partnership, Optimus will implement cutting-edge health information systems across Al Wehda’s facilities, automating patient registration, appointment scheduling, and data management to enhance operational efficiency. This digital transformation will ensure that patients receive timely care, with the assurance that their personal health information is handled with the utmost confidentiality and security. </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-c6c55d6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="c6c55d6" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Dr. Fadi Zuhair Murad, Chairman of Al Wehda Medical Group, expressed his enthusiasm for the collaboration, stating: “This partnership is a strategic opportunity to enhance collaboration between two leading institutions in the field of medical services. We are excited to work with Optimus to elevate the standards of healthcare, improve patient experiences, and drive innovation in our medical centres across Qatar". </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-c6c55d6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="c6c55d6" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Together, Al Wehda Medical Group and Optimus are committed to setting new benchmarks in the healthcare sector, ensuring that every patient receives the highest quality of care. This partnership underscores both organisations' dedication to improving healthcare outcomes and embracing the future of medical services in Qatar. </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
