import AboutUs from '../pages/AboutUs'
import Broucher from '../pages/Broucher'
import ContactUS from '../pages/ContactUS'
import DemoRequest from '../pages/DemoRequest'
import ERP from '../pages/ERP'
import Features from '../pages/Features'
import Home from '../pages/Home'
import PressRelease from '../pages/PressRelease'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import Saas from '../pages/Saas'
import Signup from '../pages/Signup'
import TermsAndConditions from '../pages/TermsAndConditions'
import ThankyouScreen from '../pages/ThankyouScreen'
import EHR from '../pages/modules/EHR'
import FM from '../pages/modules/FM'
import IP from '../pages/modules/IP'
import LIS from '../pages/modules/LIS'
import Modules from '../pages/modules/Modules'
import PAS from '../pages/modules/PAS'
import PMS from '../pages/modules/PMS'
import RCM from '../pages/modules/RCM'
import RIS from '../pages/modules/RIS'

export const routes = [

]

export const mainMenuRoutes = [
    {
        path: '/',
        component: <Home />,
    },
    {
        path: '/aboutus',
        component: <AboutUs />,
    },
    {
        path: '/features',
        component: <Features />,
    },
    {
        path: '/contact',
        component: <ContactUS />,
    },
    {
        path: '/EHR',
        component: <EHR />,
    },
    {
        path: '/pas',
        component: <PAS />,
    },
    {
        path: '/rcm',
        component: <RCM />,
    },
    {
        path: '/pms',
        component: <PMS />,
    },
    {
        path: '/lis',
        component: <LIS />,
    },
    {
        path: '/ris',
        component: <RIS />,
    },
    {
        path: '/in-patient',
        component: <IP />,
    },
    {
        path: '/finance',
        component: <FM />,
    },
    {
        path: '/erp',
        component: <ERP />,
    },
    {
        path: '/modules',
        component: <Modules />,
    },
    {
        path: '/broucher',
        component: <Broucher />,
    },
    {
        path: '/terms-conditions',
        component: <TermsAndConditions />,
    },
    {
        path: '/signup',
        component: <Signup />,
    },
    {
        path: '/privacy-policy',
        component: <PrivacyPolicy />,
    },
    {
        path: '/free-his-demo',
        component: <DemoRequest />,
    },
    {
        path: '/thankyou',
        component: <ThankyouScreen />,
    },
    {
        path: '/SaaS',
        component: <Saas />,
    },
    {
        path: '/press-release',
        component: <PressRelease />,
    },

]

export const privateRoutes = [
]