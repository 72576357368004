import React, { useEffect } from 'react'
import "../assets/css/post-3130.css"

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div class="shadepro-page-builder-wrapper">

                <div data-elementor-type="wp-page" data-elementor-id="3130" class="elementor elementor-3130">
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-2a2b562 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                        data-id="2a2b562" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div
                                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8910ca0"
                                data-id="8910ca0" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        class="elementor-element elementor-element-c89fc6d shadepro-sticky-no elementor-widget elementor-widget-heading"
                                        data-id="c89fc6d" data-element_type="widget"
                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">Privacy Policy</h2>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-element elementor-element-7a6e847 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                        data-id="7a6e847" data-element_type="widget"
                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="text-editor.default">
                                        <div class="elementor-widget-container">
                                            <p>Optimus Health Information System </p>
                                        </div>
                                    </div>
                                    {/* <section
                                        class="elementor-section elementor-inner-section elementor-element elementor-element-700fe16 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                    >
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div
                                                class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-359432e"
                                                data-id="359432e" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        class="elementor-element elementor-element-499cd9f shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                    >
                                                        <div class="elementor-widget-container">
                                                            <p>
                                                                These terms and conditions (the "Agreement") govern your access to and use of
                                                                [Your HIS Product Name] ("the Service"), provided by [Your Company Name] ("us", "we", or "our"). By accessing or using
                                                                the Service, you agree to be bound by this Agreement.
                                                                If you do not agree with any part of the terms, you may not access or use the Service.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section> */}
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">Who we are</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p>
                                                                Our website address is:
                                                                https://www.optimus-health.com.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">Comments</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>When visitors leave comments on the site we
                                                                collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to
                                                                help spam detection.</p>
                                                            <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service
                                                                to see if you are using it. The Gravatar service privacy policy is available here:
                                                                https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in
                                                                the context of your comment.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">Media</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>If you upload images to the website, you should
                                                                avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and
                                                                extract any location data from images on the website.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">Cookies</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>If you leave a comment on our site you may
                                                                opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do
                                                                not have to fill in your details again when you leave another comment. These cookies will last for one year.</p>
                                                            <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This
                                                                cookie contains no personal data and is discarded when you close your browser.</p>
                                                            <p>When you log in, we will also set up several cookies to save your login information and your screen display
                                                                choices. Login cookies last for two days, and screen options cookies last for a year. If you select "Remember Me",
                                                                your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
                                                            <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no
                                                                personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">Embedded content from other websites</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>Articles on this site may include embedded
                                                                content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way
                                                                as if the visitor has visited the other website.</p>
                                                            <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your
                                                                interaction with that embedded content, including tracking your interaction with the embedded content if you have
                                                                an account and are logged in to that website.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">Who we share your data with</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>If you request a password reset, your IP address
                                                                will be included in the reset email.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">How long we retain your data</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>If you leave a comment, the comment and its
                                                                metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically
                                                                instead of holding them in a moderation queue.</p>
                                                            <p>For users that register on our website (if any), we also store the personal information they provide in their
                                                                user profile. All users can see, edit, or delete their personal information at any time (except they cannot change
                                                                their username). Website administrators can also see and edit that information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">What rights you have over your data</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>If you have an account on this site, or have
                                                                left comments, you can request to receive an exported file of the personal data we hold about you, including any
                                                                data you have provided to us. You can also request that we erase any personal data we hold about you. This does
                                                                not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">Where we send your data</h2>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                        <div class="elementor-widget-container">
                                                            <p><strong class="privacy-policy-tutorial"></strong>Visitor comments may be checked through an
                                                                automated spam detection service.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
