import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {

    return (
        <>
            <footer class="footer-section">
                <div data-elementor-type="wp-post" data-elementor-id="5908" class="elementor elementor-5908">
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-61dbf9a elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                        data-id="61dbf9a" data-element_type="section"
                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div
                                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e624207"
                                data-id="e624207" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section
                                        class="elementor-section elementor-inner-section elementor-element elementor-element-41a33fb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                        data-id="41a33fb" data-element_type="section"
                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div
                                                class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ec0b491"
                                                data-id="ec0b491" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        class="elementor-element elementor-element-062f4d4 shadepro-sticky-no elementor-widget elementor-widget-ama-logo"
                                                        data-id="062f4d4" data-element_type="widget"
                                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                        data-widget_type="ama-logo.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="ama-site-logo content-align-center">
                                                                <Link to="/" class="ama-site-logo-wrap">
                                                                    <span class="site-logo"><img decoding="async"
                                                                        src="/assets/images/Logo-01.svg"
                                                                        alt="Optimus" class="navbar-brand__regular dark-logo" /></span> </Link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="elementor-element elementor-element-0899fa6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                        data-id="0899fa6" data-element_type="widget"
                                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                        data-widget_type="text-editor.default">
                                                        <div class="elementor-widget-container">
                                                            <p style={{ textAlign: "center" }}>Optimus is built from the ground up as a comprehensive solution
                                                                for better healthcare management.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div
                                        class="elementor-element elementor-element-9013c99 shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn"
                                        data-id="9013c99" data-element_type="widget"
                                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                        data-widget_type="shadepro-btn.default">
                                        <div class="elementor-widget-container">
                                            <div class="shadepro-btn-wrapper enable-icon-box-no">
                                                <a class="shadepro-btn btn-type-boxed elementor-animation-float"
                                                    href="/assets/Optimus-Broucher-2.pdf" target='_blank'>
                                                    View Brochure
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-element elementor-element-dd0cf2b elementor-shape-rounded elementor-grid-0 e-grid-align-center shadepro-sticky-no elementor-widget elementor-widget-social-icons"
                                        data-id="dd0cf2b" data-element_type="widget"
                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="social-icons.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-social-icons-wrapper elementor-grid">
                                                <span class="elementor-grid-item">
                                                    <a class="elementor-icon elementor-social-icon elementor-social-icon-paper-plane elementor-repeater-item-ec2e16d"
                                                        target="_blank">
                                                        <span class="elementor-screen-only">Paper-plane</span>
                                                        <FontAwesomeIcon icon={faPaperPlane} /> </a>
                                                </span>
                                                <span class="elementor-grid-item">
                                                    <a class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-repeater-item-6f3a6b8"
                                                        target="_blank">
                                                        <span class="elementor-screen-only">Facebook-f</span>
                                                        <FontAwesomeIcon icon={faFacebook} /> </a>
                                                </span>
                                                <span class="elementor-grid-item">
                                                    <a class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-85d6620"
                                                        target="_blank">
                                                        <span class="elementor-screen-only">Twitter</span>
                                                        <FontAwesomeIcon icon={faTwitter} /> </a>
                                                </span>
                                                <span class="elementor-grid-item">
                                                    <a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-1310f9b"
                                                        target="_blank">
                                                        <span class="elementor-screen-only">Instagram</span>
                                                        <FontAwesomeIcon icon={faInstagram} /> </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-element elementor-element-1c160d2 elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                        data-id="1c160d2" data-element_type="widget"
                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="icon-list.default">
                                        <div class="elementor-widget-container">
                                            <ul class="elementor-icon-list-items elementor-inline-items">
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="/aboutus">
                                                        <span class="elementor-icon-list-text">About Us</span>
                                                    </Link>
                                                </li>
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="/modules">
                                                        <span class="elementor-icon-list-text">Modules</span>
                                                    </Link>
                                                </li>
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="/features">
                                                        <span class="elementor-icon-list-text">Features</span>
                                                    </Link>
                                                </li>
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="/erp">
                                                        <span class="elementor-icon-list-text">ERP</span>
                                                    </Link>
                                                </li>
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="/press-release">
                                                        <span class="elementor-icon-list-text">Press Release</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-element elementor-element-c7e990b elementor-icon-list--layout-inline elementor-mobile-align-center elementor-widget__width-auto elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                        data-id="c7e990b" data-element_type="widget"
                                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="icon-list.default">
                                        <div class="elementor-widget-container">
                                            <ul class="elementor-icon-list-items elementor-inline-items">
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="/privacy-policy">

                                                        <span class="elementor-icon-list-text">Privacy Policy</span>
                                                    </Link>
                                                </li>
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="/terms-conditions">

                                                        <span class="elementor-icon-list-text">Terms &amp; Conditions </span>
                                                    </Link>
                                                </li>
                                                <li class="elementor-icon-list-item elementor-inline-item">
                                                    <Link to="#">
                                                        <span class="elementor-icon-list-text">All Rights Reserved with Optimus</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </footer>
        </>
    )
}
